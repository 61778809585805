:root {
  --color-success-600: #16a34a;
  --color-contenur-orange: #ff6917;
  --color-contenur-bluesky: #98d4df;
  --color-contenur-bluedark: #323e46;
  --color-destructive-500: #ef4444;
  --color-neutral-200: #e5e5e5;
  --color-neutral-100: #f5f5f5;
  --color-white: #fff;
}

/* Buttons */
.btn {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 14px 58px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn-orange {
  background-color: var(--color-contenur-orange);
  color: var(--color-white);
  border: 1px solid var(--color-contenur-white);
}

.btn-white {
  background-color: var(--color-white);
  color: var(--color-neutral-200);
  border: 1px solid var(--color-neutral-200);
}
.btn-white-destructive {
  background-color: var(--color-white);
  color: var(--color-destructive-500);
  border: 1px solid var(--color-destructive-500);
}
.btn-success {
  background-color: var(--color-success-600);
  color: var(--color-white);
}

.layout {
  max-width: 100vw;
  height: 100vh;
  max-height: 100%;
}

.navbar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 75px;
}

.navbar .logo {
  max-width: 218px;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.transport-page {
  gap: 64px;
}
.transport-page .albaran-state {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.albaran-container {
  background-color: var(--color-contenur-bluesky);
  color: var(--color-contenur-bluedark);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  flex-direction: column;
}
.albaran-container .albaran-container-text {
  letter-spacing: -0.02em;
  font-weight: 700;
  line-height: 28px;
  font-size: 20px;
  padding: 8px;
}
.transport-page .btns-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.DropDownContainer {
  width: 100%;
  max-width: 195px;
  margin: 0 auto;
  position: relative;
}

.DropDownHeader {
  margin-bottom: 0.8em;
  padding: 12px 8px 12px 8px;
  font-weight: 500;
  font-size: 17px;
  color: var(--color-contenur-bluedark);
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.DropDownHeaderText {
  display: flex;
  align-items: center;
  gap: 8px;
}

.DropDownListContainer {
  position: absolute;
  width: 100%;
}
.DropDownList {
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  color: var(--color-contenur-bluedark);
  font-size: 17px;
  border-radius: 4px;
  font-weight: 500;
}

.DropDownList li:nth-child(even) {
  background: var(--color-neutral-100);
}

.ListItem {
  padding-left: 1em;
  list-style: none;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.delayed-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.delayed-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.picker-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.picker {
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}
.picker-icon {
  margin: 6px;
}
.picker-error {
  color: red;
  font-size: 12px;
  text-align: left;
}
.picker-input {
  border: none;
  color: #a3a3a3;
  padding: 6px;
  width: 93%;
  border-radius: 6px;
}
.btn-ref-picker {
  width: 100%;
  padding: 6px;
  background: transparent;
  border: none;
  text-align: left;
}
.success-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.success-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-neutral-100);
  justify-content: center;
  align-items: center;
  max-width: 211px;
  padding: 8px;
}
.success-message {
  font-size: 18px;
  line-height: 28px;
  color: var(--color-contenur-bluedark);
  font-weight: 600px;
  text-align: center;
}

.transport-page {
  justify-content: center;
  align-items: center;
}

.img-placeholder {
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  display: block;
}
